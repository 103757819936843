/* Pagination css */

.pagination-container {
    display: flex;
    list-style-type: none;
}

.pagination-item {
    height: 30px;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 13px;
    width: 30px;
}

.pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.pagination-item.selected {
    background-color: #0F5EFF;
    color: white;
}

.pagination-item .arrow::before {
    position: relative;
    content: '';
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
    transform: rotate(45deg);
    margin-bottom: 2px;
}

.pagination-item.disabled {
    pointer-events: none;
}

.pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width:1919px) {
    .pagination-item {
        height: 30px;
        margin: auto 4px;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 13px;
        width: 30px;
    
    
        height: 20px;
        margin: auto 4px;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 10px;
        width: 20px;
    }



}