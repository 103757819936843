

.icon {
    position: absolute;
    right: 10px;
}


.viewbuttons {
    display: flex;
    width: 75px;
    height: 35px;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
}


.listicon_wrapper {
    width: 50%;
    height: 100%;
    cursor: pointer;
    border-radius: 3px 0px 0px 3px;
}

.gridicon_wrapper {
    width: 50%;
    height: 100%;
    cursor: pointer;
    border-radius: 0px 3px 3px 0px;
}

.img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.listicon_wrapper.active,
.gridicon_wrapper.active {
    background-color: #3988F9;
}

.listicon_wrapper img,.gridicon_wrapper img{
    height: 20px;
}

@media screen and (max-width:1919px) {
    .viewbuttons{
        width: 65px;
        height: 30px;
    }
    .listicon_wrapper img{
        height: 16px;
    }
    .gridicon_wrapper img{
        height: 16px;
    }
}