.histogram{
    user-select: none;
    margin-bottom: 8px;

    &.disabled{
        opacity: 0.5;
        pointer-events: none;
    }
    
    .histogram-container{
        &:hover{
            cursor: pointer;
        }
    }
    
    select, input{
        height: auto;
        padding: 4px;
    }

    .min-max{
        display: inline;
        width: 121px;
        font-size: 12px;
        padding : 5px 0 5px 10px ;
    }

    .axis {
        shape-rendering: crispEdges;
      }
      
      .x.axis line {
        display: none;
      }
      
      .x.axis .minor {
        stroke-opacity: .5;
      }
      
      .x.axis path {
        display: none;
      }

      .theme-stroke-primary{
        stroke: #2989cf;
      }

    .slider-line{
        stroke-width: 4;
        opacity: 1;
        &:hover{
            cursor: ew-resize;
        }
    }
}